import { useContext, useEffect, useState, useRef } from "react";
import { ToastContainer } from "react-toastify";
import logo from "../img/logosmall.png";
import { PrimaryContext } from "../components/MainContext";
import { useNavigate } from "react-router-dom";
import { errorToast } from "../components/ToastAlerts";

const HomePage = () => {
  const navigate = useNavigate();
  //context data
  const { handleEnterRoom, setUserType, userType, roomId, setRoomId } =
    useContext(PrimaryContext);
  //show navigation
  const [showNavigation, setShowNavigation] = useState(true);
  const inputRef = useRef(null);

  const handleDocumentClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  //handle room id
  const handleRoomId = (e) => {
    setRoomId(e.target.value);
  };

  //type with keyboard
  const handleTypeWithScreen = (newNumber) => {
    setRoomId((prev) => (isNaN(prev) ? newNumber : prev + newNumber));
  };

  //type with keyboard
  const handleDeleteNumberButton = () => {
    setRoomId((prev) => prev.toString().slice(0, -1));
  };

  //handle enter keystroke
  const handleEnterKeyStoke = (e) => {
    if (e.key === "Enter") {
      handleEnterRoom(roomId);
    }
  };

  //handle redirect
  const handleRedirect = (path, userTypeString) => {
    localStorage.setItem("USER_STRING", userTypeString);
    const type = parseInt(localStorage.getItem("USER"));
    if (type === 4 || type === 5 || type === 6) {
      navigate(path);
      setShowNavigation(false);
    } else {
      errorToast("გთხოვთ მონიშნნეთ ციფრი!");
    }
  };

  //redirect on correct page on load
  useEffect(() => {
    const type = parseInt(localStorage.getItem("USER"));
    if (type === 4 || type === 5 || type === 6) {
      setShowNavigation(false);
    }
  }, []);

  //handle choose type
  const handleChooseType = (id) => {
    setUserType(id);
    localStorage.setItem("USER", id);
  };

  return (
    <div
      onClick={handleDocumentClick}
      className="w-screen h-screen flex items-center justify-center relative"
    >
      {showNavigation && (
        <div className="absolute top-10 left-[50%] translate-x-[-50%] flex items-center gap-2">
          <button
            className="p-3 border border-slate-300 shadow-md
   rounded-lg text-slate-500 text-sm font-bold bg-white"
            onClick={() => {
              handleRedirect("/", "user");
            }}
          >
            მომხმარებელი
          </button>
          <button
            className="p-3 border border-slate-300 shadow-md
   rounded-lg text-slate-500 text-sm font-bold bg-white"
            onClick={() => {
              handleRedirect("/admin-page", "admin");
            }}
          >
            ადმინისტრატორი
          </button>
          <button
            className={`p-3 border border-slate-300 shadow-md
   rounded-lg text-sm font-bold ${
     userType === 4 ? "bg-green-500 text-white" : "bg-white text-slate-500"
   }`}
            onClick={() => {
              handleChooseType(4);
            }}
          >
            4
          </button>
          <button
            className={`p-3 border border-slate-300 shadow-md
   rounded-lg text-sm font-bold ${
     userType === 5 ? "bg-green-500 text-white" : "bg-white text-slate-500"
   }`}
            onClick={() => {
              handleChooseType(5);
            }}
          >
            5
          </button>
          <button
            className={`p-3 border border-slate-300 shadow-md
   rounded-lg text-sm font-bold ${
     userType === 6 ? "bg-green-500 text-white" : "bg-white text-slate-500"
   }`}
            onClick={() => {
              handleChooseType(6);
            }}
          >
            6
          </button>
        </div>
      )}
      <div className="flex flex-col gap-5 items-center justify-center px-10">
        <div className="flex flex-col justify-center items-center gap-5 w-full lg:w-[500px]">
          <img src={logo} className="md:h-32 h-20" />
          <p className="font-bold text-slate-600 md:text-3xl text-xl w-full text-center">
            მიუთითეთ პირად კაბინეტში არსებული შტრიხ კოდი ან პირადი ნომერი
          </p>
          <input
            ref={inputRef}
            className="w-full border border-slate-300 shadow-sm rounded-lg outline-none pl-2
                text-center h-20 text-2xl"
            onChange={(e) => {
              handleRoomId(e);
            }}
            value={roomId}
            onKeyDown={(e) => {
              handleEnterKeyStoke(e);
            }}
            type="text"
            placeholder="პირადი ნომერი"
            autoFocus
            inputMode="none"
          />
          <div className="grid grid-cols-1 gap-2 w-full">
            <div className="grid grid-cols-3 gap-2">
              <button
                className="w-full border border-slate-300 shadow-sm rounded-lg bg-white px-2 py-6 text-slate-500 font-bold text-2xl"
                onClick={() => {
                  handleTypeWithScreen("1");
                }}
              >
                1
              </button>
              <button
                className="w-full border border-slate-300 shadow-sm rounded-lg bg-white px-2 py-6 text-slate-500 font-bold text-2xl"
                onClick={() => {
                  handleTypeWithScreen("2");
                }}
              >
                2
              </button>
              <button
                className="w-full border border-slate-300 shadow-sm rounded-lg bg-white px-2 py-6 text-slate-500 font-bold text-2xl"
                onClick={() => {
                  handleTypeWithScreen("3");
                }}
              >
                3
              </button>
            </div>
            <div className="grid grid-cols-3 gap-2">
              <button
                className="w-full border border-slate-300 shadow-sm rounded-lg bg-white px-2 py-6 text-slate-500 font-bold text-2xl"
                onClick={() => {
                  handleTypeWithScreen("4");
                }}
              >
                4
              </button>
              <button
                className="w-full border border-slate-300 shadow-sm rounded-lg bg-white px-2 py-6 text-slate-500 font-bold text-2xl"
                onClick={() => {
                  handleTypeWithScreen("5");
                }}
              >
                5
              </button>
              <button
                className="w-full border border-slate-300 shadow-sm rounded-lg bg-white px-2 py-6 text-slate-500 font-bold text-2xl"
                onClick={() => {
                  handleTypeWithScreen("6");
                }}
              >
                6
              </button>
            </div>
            <div className="grid grid-cols-3 gap-2">
              <button
                className="w-full border border-slate-300 shadow-sm rounded-lg bg-white px-2 py-6 text-slate-500 font-bold text-2xl"
                onClick={() => {
                  handleTypeWithScreen("7");
                }}
              >
                7
              </button>
              <button
                className="w-full border border-slate-300 shadow-sm rounded-lg bg-white px-2 py-6 text-slate-500 font-bold text-2xl"
                onClick={() => {
                  handleTypeWithScreen("8");
                }}
              >
                8
              </button>
              <button
                className="w-full border border-slate-300 shadow-sm rounded-lg bg-white px-2 py-6 text-slate-500 font-bold text-2xl"
                onClick={() => {
                  handleTypeWithScreen("9");
                }}
              >
                9
              </button>
            </div>
            <div className="grid grid-cols-3 gap-2">
              <button
                className="w-full border border-slate-300 shadow-sm rounded-lg bg-white px-2 py-6 text-slate-500 font-bold text-2xl"
                onClick={() => {
                  handleTypeWithScreen("0");
                }}
              >
                0
              </button>
              <button
                className="w-full border border-slate-300 shadow-sm rounded-lg bg-white px-2 py-6 text-slate-500 font-bold text-2xl"
                onClick={handleDeleteNumberButton}
              >
                X
              </button>
              <button
                className="bg-green-500 text-white p-2 rounded-lg shadow-sm"
                onClick={() => {
                  handleEnterRoom(roomId);
                }}
              >
                ✓
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default HomePage;
