import { useState } from "react";
import { FaRegFaceSmileWink } from "react-icons/fa6";
import { errorToast } from "./ToastAlerts";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Review({ userId, setPopup, setUserData }) {
  const stars = Array.from({ length: 5 }, (_, i) => i + 1);
  const [newReview, setNewReview] = useState({});
  const navigate = useNavigate();

  const handleNewReview = (value, name) => {
    setNewReview((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateNewReview = () => {
    if (!newReview.score) {
      errorToast("შეავსე ყველა ველი");
      return;
    }

    axios
      .post(`https://back.mglparcel.com/api/rateus`, {
        ...newReview,
        question_id: 1,
        user_id: userId,
      })
      .then((res) => {
        setPopup(false);
        navigate("../");
        setUserData({});
      })
      .catch((err) => {});
  };
  return (
    <div className="flex flex-col gap-5">
      <h1 className="text-center text-xl">შეგვაფასეთ</h1>

      <div className="gap-2 grid grid-cols-5">
        {stars?.map((star) => {
          return (
            <FaRegFaceSmileWink
              key={star}
              onClick={() => {
                handleNewReview(star, "score");
              }}
              className={`cursor-pointer text-6xl md:text-4xl ${
                star <= newReview?.score ? "text-yellow-400" : "text-slate-400"
              }`}
            />
          );
        })}
      </div>
      <div className="w-full flex justify-center">
        <button
          onClick={handleCreateNewReview}
          className="text-white bg-green-500 py-3
px-10 text-sm self-start rounded-[5px]"
        >
          შეფასების დატოვება
        </button>
      </div>
    </div>
  );
}
